import React from 'react';
import './home.css';
import { Link } from "react-router-dom";
const Home = () => {
  function handleClick() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  return (
    
    <div>
      
    <div class="strona-glowna">
      <div class="strona-glowna-content">
        <div class="container">
          <div class="col-1">
            
            <h1>Niepubliczne Przedszkole Integracyjne</h1>
            <h2>Ania z Zielonego Wzgórza</h2>
            <p>
            "Czy to nie przyjemnie, że jest tak dużo rzeczy, które jeszcze poznamy? To właśnie sprawia, że ja się tak cieszę życiem... świat jest taki ciekawy... Nie byłby taki ani w połowie, gdybyśmy wszystko o nim wiedzieli, prawda?"

            </p>
            <p>Lucy Maud Montgomery,
"Ania z Zielonego Wzgórza"</p>
            <div class="buttons">
            <Link onClick={() => handleClick()} to="/onas" ><button class="btn">O Nas</button></Link>
            <Link onClick={() => handleClick()} to="/rekrutacja" ><button class="btn">Zapisz się</button></Link>
          </div>
          </div>
          <div class="col-2">
            <img src="./main-img2.jpg" alt="main-img" />
          </div>
        
      </div>
    </div>
    </div>

    

    <div class="o-nas">
    
      <div class="container-1">
        <div class="col-1">
          <div class="col-1-img">
            <img src="./onas-3.jpg" alt="onas" />
          </div>
        </div>
        <div class="col-2">
          
          <div class="col-2-text">
            <h5>Przedszkole dla wszystkich</h5>
            <h2>Dowiedz się wiecej o nas</h2>
            <p>
            Jesteśmy pierwszym przedszkolem integracyjnym w Sokołowie Podlaskim w pełni przystosowanym do realizacji
zadań edukacyjno-wychowawczych dla wszystkich dzieci, również tych ze specjalnymi potrzebami edukacyjnymi.
            </p>
          </div>
          <ul class="col-2-list">
            <li>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check-circle"
                class="svg-inline--fa fa-check-circle fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                ></path>
              </svg>
              Tworzone grupy są kameralne
            </li>
            <li>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check-circle"
                class="svg-inline--fa fa-check-circle fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                ></path>
              </svg>
              Wszystkie zajęcia ( również te dodatkowe ) realizowane są w ramach czesnego
            </li>
            <li>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check-circle"
                class="svg-inline--fa fa-check-circle fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                ></path>
              </svg>
              Oferujemy kompleksową, codzienną terapię
            </li>
            <li>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="check-circle"
                class="svg-inline--fa fa-check-circle fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                ></path>
              </svg>
              Pracujemy również w wakacje oraz ferie zimowe
            </li>
          </ul>
          <div class="col-2-text2">
            <p>
            Zadbamy o to, aby Twoje dziecko czuło się bezpiecznie i komfortowo. Rodzinna atmosfera , dużo zabawy i
fascynujących zajęć, a także indywidulane podejście do każdego dziecka- to właśnie wyróżnia nasze
przedszkole.
            </p>
            <h5 class="have__q3">
              Masz jakies pytania?<Link onClick={() => handleClick()} to="/kontakt"><a class="start__free" href="/"
                >Skontaktuj sie z nami</a></Link>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="o-nas-2">
      <div class="container-3">
        <div class="row-1">
          <div class="row-1-item1">
            
              <img src="./onas-4.jpg" alt="onas4" />
            
          </div>
          <div class="row-1-item2">
            <div class="row-1-item2-content">
              <div class="row-1-item2-text">
                <h5>Zarejestruj się już dziś</h5>
                <h2>Jesteśmy Najlepszym Wyborem Dla Twojego Dziecka</h2>
                <p>
                Komfort każdego dziecka jest dla nas priorytetem, dlatego dużą wagę przywiązujemy do procesu
adaptacji. Przygotowujemy dzieci do dalszego rozwoju i kolejnego etapu edukacji, jakim jest szkoła.
                </p>
              </div>
              <div class="row-1-item2-list">
                <div class="list-item">
                  <div class="list-item-icon">
                    <img src="./icon1.png" alt="" />
                  </div>
                  <div class="list-item-text">
                    <h5>Wykwalifokowana opieka</h5>
                    <p>
                    Zespół kreatywnych, zaangażowanych nauczycieli- specjalistów stwarza doskonałe warunki do wspierania
rozwoju dziecka.
                    </p>
                  </div>
                </div>
                <div class="list-item">
                  <div class="list-item-icon">
                    <img src="./icon2.png" alt="" />
                  </div>
                  <div class="list-item-text">
                    <h5>Bogata oferta zajęć</h5>
                    <p>
                    Stawiamy na rozwój dzieci, tworząc optymalne warunki edukacyjne przy użyciu najnowszych
technik oraz realizując zajęcia dodatkowe.
                    </p>
                  </div>
                </div>
                <div class="list-item">
                  <div class="list-item-icon">
                    <img src="./icon3.png" alt="" />
                  </div>
                  <div class="list-item-text">
                    <h5>Doskonała infrastruktura</h5>
                    <p>
                    Dysponujemy przestronnymi -nowocześnie wyposażonymi salami zajęć, salą światła , salą do rehabilitacji
oraz gabinetami ( logopedyczny, integracji sensorycznej) .
Posiadamy również nowoczesny Plac Zabaw na terenie Przedszkola
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div class="o-nas-3">
      <div class="side-img1">
        <img src="./elem-5.svg" alt="" />
      </div>
      

      <div class="container-2">
        <div class="row-1">
          <div class="row-1-text">
            <h5>Zaufanie to podstawa</h5>
            <h2>Poznaj nasz zespół</h2>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Accusantium dolores culpa velit, nam fugiat doloremque!
            </p>
          </div>
        </div>
        <div class="row-2">
          <div class="row-2-item">
            <div class="row-2-item-img">
              <img src="./onas-2.jpg" alt="" />
            </div>
            <div class="row-2-item-content">
              <div class="row-2-item-text">
                <h5>Andrzej Białach</h5>
                <h6>Właściciel</h6>
              </div>
            </div>
          </div>
          <div class="row-2-item">
            <div class="row-2-item-img">
              <img src="./onas-2.jpg" alt="" />
            </div>
            <div class="row-2-item-content">
              <div class="row-2-item-text">
                <h5>Andrzej Białach</h5>
                <h6>Właściciel</h6>
              </div>
            </div>
          </div>
          <div class="row-2-item">
            <div class="row-2-item-img">
              <img src="./onas-2.jpg" alt="" />
            </div>
            <div class="row-2-item-content">
              <div class="row-2-item-text">
                <h5>Andrzej Białach</h5>
                <h6>Właściciel</h6>
              </div>
            </div>
          </div>
          <div class="row-2-item">
            <div class="row-2-item-img">
              <img src="./onas-2.jpg" alt="" />
            </div>
            <div class="row-2-item-content">
              <div class="row-2-item-text">
                <h5>Andrzej Białach</h5>
                <h6>Właściciel</h6>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div> */}
    
  </div>
    
  );
};
  
export default Home;